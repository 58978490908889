/* COLOURS */
$primary-color: #000000;
$secondary-color: #0051ba;
$tertiary-color: #6c5b7b;
$quaternary-color: #245953;
$primary-alternate: #fff;

$success-color: #198754;
$error-color: #e03e3e;
$warning-color: #ff854f;
$info-color: #707070;
$grey-color: #999999;
$muted-color: #f7f7f7;
$disabled-color: #dedddd;
$payment-color: #04955f;
$white: #fff;
$black: #000;

$auction-color: $secondary-color;
$marketplace-color: $primary-color;
$lotto-color: $tertiary-color;
$wallet-color: $primary-color;
$points-color: $primary-color;
$ecash-color: $secondary-color;
$events-color: $quaternary-color;
$predictor-color: $primary-color;
$poll-color: $primary-color;
$quiz-color: $primary-color;

$marketplace-0-color: $primary-color; // FortressGB
$marketplace-1-color: $primary-color; // Unique Code
$marketplace-2-color: $primary-color; // Download
$marketplace-3-color: $events-color; // Event
$marketplace-4-color: $tertiary-color; // Product
$wallet-color: $secondary-color;
$wallet-redeemed-color: $muted-color;

$navbar-color: $black;
$navbar-link-color: $white;
$navbar-link-active-color: $white;
$navbar-progress-color: $secondary-color;
$navbar-secondary-color: $white;

/* FONTS */

@font-face {
  font-family: 'GT-America';
  src: url('../app/fonts/GT America/GT-America-Condensed-Black.otf') format('truetype');
}

$font-default: 'Open Sans', Arial, sans-serif;
$font-heading: 'GT-America', Arial, sans-serif;

/* GENERAL */

$navbar-height: 54px;
$navbar-mobile-height: 50px;
$footer-height: 180px;
$footer-mobile-height: 180px;
$panel-height: 150px;
$cta-height: 50px;
$main-padding-bottom: 40px;
$loyalty-image-height: 390px;
$border-radius: 16px;
$border-radius-button: 20px;
$card-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.15);
$nav-secondary-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
$read-more-overlay-enabled: false;

/* Reward status disabled overrides */
$reward-status-disabled-colors: (
  'coming-soon': (
    $grey-color,
    $white,
  ),
  'not-enough-points': (
    $grey-color,
    $error-color,
  ),
  'sold-out': (
    $grey-color,
    $info-color,
  ),
  'limit-reached': (
    $grey-color,
    $error-color,
  ),
  'disable-entry': (
    $grey-color,
    $white,
  ),
  'item-locked': (
    $grey-color,
    $white,
  ),
);
