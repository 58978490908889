@import './sass/variables';
@import '@fgb/portal-component-library/styles';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';

/* ELEMENT STYLING */
html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    @if $light-theme {
      background: transparent;
      color: black;
    } @else {
      background: #000;
      color: white;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  font-weight: normal !important;
  font-family: $font-heading;
}

/* Placeholders */

.no-items {
  .no-results-placeholder-title {
    font-family: $font-heading;
    font-size: 25px;
    font-weight: 400;
  }

  .no-results-placeholder-description {
    font-size: 0.875rem;
    color: $black;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

.nav-secondary {
  display: flex;
  align-items: center;
  .nav-item.active {
    @media (max-width: 991px) {
      width: 5%;
    }
  }
}

$primary: $primary-color;

/* BOOTSTRAP OVERRIDES */
$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'auction': $auction-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $tertiary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'mp-virtual-event': $events-color,
  'mp-product': $tertiary-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'ecash': $ecash-color,
  'predictor': $predictor-color,
  'poll': $poll-color,
  'quiz': $quiz-color,
  'affiliate': $primary-color,
  'transfer': $primary-color,
  'white': #ffffff,
  'black': #000000,
  'sold-out': #70707033,
  'coming-soon': #70707033,
  'limit-reached': #e03e3e33,
  'not-enough-points': #e03e3e33,
  'add-card': #ce1126,
  'active-card': $secondary-color,
  'inactive-card': $disabled-color,
  'frozen-card': $frozen-color,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

$date-select-box-shadow: 0 0 0 0.2rem rgb(60 85 124 / 25%);

/* Form Colors */

.form-control {
  color: #000000;
}

/* Banner */

.banner {
  background: url('../src/assets/images/banners/MobileBanner.jpg');
  background-size: cover !important;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  @media (min-width: 991px) {
    padding-left: 3rem;
    background: url('../src/assets/images/banners/DesktopBanner.jpg');
  }

  h1 {
    font-size: 40px;
    color: $white;
  }
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner-demo.jpg);
  background-size: cover;
  background-position: center;
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.filter-modal-dialog {
  display: flex;
  align-items: flex-end !important;
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

/* Placeholder Styling */

.no-items {
  .no-results-placeholder-icon {
    max-width: 200px;
    margin: 18px auto;

    svg {
      height: 75px;
    }

    &.material-icons-two-tone {
      font-size: 4rem;
      margin: 18px auto 0;
    }
  }

  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }

  &.no-results-placeholder-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-color;
    height: 50px;
    @media (max-width: 991px) {
      margin: 0px;
    }

    .no-results-placeholder {
      font-size: 0.75rem;
    }
  }
}

.increment-button,
.decrement-button {
  svg {
    height: 13px;
    width: 13px;
  }
}

.svg-marketplace-details-icons {
  svg {
    height: 14px;
    width: 14px;
  }
}

.redeem-list-item-icons {
  font-size: 18px;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
}

.all-offers-steps {
  .card-link-earn-step-box {
    .card-title {
      font-size: 14px;
      color: #c80550;
    }
    .color-1 {
      color: #c80550;
    }
    .color-2 {
      color: #82bee6;
    }
    .color-3 {
      color: #7d4605;
    }
    .card-link-earn-step-icon-border-1 {
      border: 1px solid #c80550;
    }
    .card-link-earn-step-icon-border-2 {
      border: 1px solid #82bee6;
    }
    .card-link-earn-step-icon-border-3 {
      border: 1px solid #7d4605;
    }
  }
}

.nav.nav-secondary {
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;
  }
}

.announcements-carousel {
  .owl-item {
    padding-left: 1px !important;
    @media (max-width: 992px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width: 992px) {
      margin-left: 6px !important;
      margin-right: 2px !important;
      width: 944px !important;
    }
  }
  .owl-carousel .owl-stage-outer {
    padding-left: 0px !important;
  }
}

fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
}

.register-panel {
  .container {
    > *:not(router-outlet) {
      display: flex;
      flex-direction: column;
      height: inherit;
    }
  }
}

.registration-payment-modal {
  .registration-payment-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    background-color: $primary-color;
    color: #ffffff;

    .registration-payment-modal-title {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    .close-btn {
      cursor: pointer;
      margin-left: auto;
      font-family: 'Material Icons Outlined';
    }
  }

  .payment-container {
    display: flex;
    justify-content: center;
  }

  .payment-form {
    max-width: 310px;
  }
}

.invalid-token-modal {
  max-width: 700px;

  .modal-content {
    min-height: 280px;
    padding: 15px;
  }
}

/** Scorecard Carousel */
.badge-carousel-div {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

//payment

.ecash-description-box .ecash-description-icon .color-2 {
  color: $primary-color;
}

.ecash-description-box h5 {
  font-size: 14px !important;
  font-weight: 900 !important;
}
.btn.add-card {
  width: 142px;
  height: 120px;

  @media (max-width: 450px) {
    width: 366px;
    height: 34px;
  }
}

.form-floating input.form-control,
.form-floating select {
  font-size: 14px !important;
}

.payment-card {
  width: 224px;
}

.payment-card .card-status {
  font-size: 14px;
}

.date-range-picker .icon {
  font-size: 1.5rem;
}

.add-card-label {
  text-transform: capitalize !important;
}

.form-control {
  border-color: $disabled-color !important;
}

//contact us
.contact-us-input-group {
  border-color: $disabled-color;
}

textarea.input-group {
  padding: 5px 10px;
  font-size: 12px;
}

.contact-us-dropdown {
  font-size: 12px !important;
}

.contact-us-dropdown-div {
  margin-bottom: 30px !important;
}

//terms and conitions
.terms-section-content h6 {
  font-size: 14px;
  font-weight: bold;
}

.terms-section-box {
  border: 1px solid $disabled-color;
}

.terms-section-content p {
  margin-bottom: 1.5rem;
}

//faqs
.faq-section-title {
  font-size: 18px !important;

  @media (max-width: 450px) {
    font-size: 20px !important;
  }
}

.faq-section-card-title {
  font-size: 14px;
}

.accordion-button {
  font-size: 14px !important;
}

.faq-section-card-description {
  font-size: 14px !important;
}

//announcement
.announcements-main-title {
  text-transform: uppercase;
  font-size: 18px !important;

  @media (max-width: 450px) {
    font-size: 20px !important;
  }
}

.announcements-title {
  font-weight: bold !important;
}

.announcements-container {
  border-color: $disabled-color !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 8px !important;
  height: 9px !important;
  background-color: $disabled-color;
}

.announcement-link {
  @media (max-width: 450px) {
    padding-top: 1rem;
  }
}

.announcement-coming-soon label,
.countdown-timer {
  color: $black !important;
}

@import 'bootstrap/scss/bootstrap';

/* Requiring Bootstrap */
@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}

/* Registration */

fgbcl-registration-terms-and-conditions,
fgbcl-registration-personal-details {
  background-color: $white;
  border-radius: 16px;
  padding: 20px;
}

fgbcl-registration-navigation {
  .btn-previous-step {
    border: none !important;
  }
}

fgbcl-registration-personal-details {
  .logo {
    display: none !important;
  }

  h5 {
    text-align: left !important;
    margin-bottom: 20px !important;
  }

  .registration-personal-details .model-meta-data-form-container {
    @media (min-width: 992px) {
      height: 430px;
    }
  }
}
